import React, { useEffect, useRef, useState } from "react";
import "./Bond.css";
import { Modal, Container } from "react-bootstrap";
import { Card } from "reactstrap";
import "./Popup.css";

function PopupModal({ open, data, handleHide, onSelect }) {
  // let skills = data;
  const [smShow, setSmShow] = useState(false);

  // const [selectedSkill, setSelectedSkill] = useState("");
  const [dropdownSearchValue, setDropdownSearchValue] = useState(data);
  const [editMode, setEditMode] = useState(false);
  const dropdownRef = useRef();
  const onClick = () => {
    setSmShow(false);
    handleHide();
  };
  
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        editMode &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setEditMode(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [editMode]);

  useEffect(() => {
    setSmShow(open);
  }, [open]);

  const skillSelectionHandler = (skill) => {
    // setSelectedSkill(skill.curr);
    onSelect(skill);
    setDropdownSearchValue("");
    setEditMode(false);
    handleHide();
  };

  data.filter((skill) =>
    skill.curr.match(new RegExp(dropdownSearchValue, "i"))
  );
  return (
    <>
      {/* <Button onClick={() => setSmShow(true)}>Popup</Button>{" "} */}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => onClick()}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Card className="card-popup">
          <Container>
            <div className="main-popup">
              <h2 className="token_sel">Select TOKEN</h2>

              {/* {editMode ? (
                // display the dropdown when the input us focused */}
              <div ref={dropdownRef} className="dropdown-wrapper">
                {/* <input
                    className="dropdown-input"
                    name="dropdown-input"
                    autoFocus
                    // onChange={(e) => setDropdownSearchValue(e.target.value)}
                    value={dropdownSearchValue}
                    placeholder="Search name or enter address"
                  /> */}
                <div className="dropdown-list">
                  <ul>
                    {data.map((skill) => {
                      return (
                        <>
                          <li
                            key={skill.curr}
                            onClick={() => skillSelectionHandler(skill)}
                          >
                            <img
                              src={skill.img}
                              alt="Happy Emoticons"
                              className="demoimg"
                            />
                            {skill.curr}{" "}
                          </li>
                        </>
                      );
                    })}
                    {data.length === 0 && (
                      <li className="no-result">No results found</li>
                    )}
                  </ul>
                </div>
              </div>
              {/* ) : (
                <input
                  // Grey out the text when "Select Primary skill" input hint is shown
                  className={`dropdown-search ${
                    !(dropdownSearchValue || selectedSkill) && "default"
                  }`}
                  onFocus={() => setEditMode(true)}
                  // Display the selected skill or "Select Primary skill" input hint
                  value={selectedSkill || "Search name or enter address"}
                />
              )} */}
            </div>
          </Container>
        </Card>

        {/* </Modal.Body> */}
      </Modal>
    </>
  );
}

export default PopupModal;
