import { React, useState, useEffect } from "react";
import Topbar from "./Topbar/topbar";
import { Card, Row, Col } from "reactstrap";
import { Table } from "react-bootstrap";
// import { Container } from "react-bootstrap";
import "./graph.css";
import { Container, Button } from "react-bootstrap";
// import Chart from './Chart';
// import { getData } from "../utils"
// import vector from "../assets/images/Vector.png"
import arrow from "../assets/images/arrow.png";
// import { TypeChooser } from "react-stockcharts/lib/helper";
import { useLocation } from "react-router-dom";
import PopupModal from "./Popup";
// import hug from "../../src/assets/images/hug.png";
import Vector from "../../src/assets/images/vectorblue.png";
// import Usdc from "../../src/assets/images/usdc.png";
import Usdt from "../../src/assets/images/usdt.png";
import Dai from "../../src/assets/images/dai.png";
import Usdc from "../../src/assets/images/usdc.png";
import axios from "axios";
import Web3 from "web3";
import abi from "erc-20-abi";
import contract_abi1 from "./JSON/contract_abi1.json";
import contract_abi from "./JSON/contract_abi.json";
import loadinggif from "../../src/assets/images/loader.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

let skills = [
  { img: Vector,
    curr: "ETH", 
    name: "Ethereum", 
    ids_: "ethereum", 
    address: "" },
  {
    img: Usdt,
    curr: "USDT",
    name: "Tether",
    ids_: "tether",
    // address: "0x5a1091C846db74571994FD7DA9851d5D6798dB70",
    //is our own contract address-
    address:"0xf6972F489b08652e67Dadb01D5186bBebf67e699",
    decimals: "18",
  },
  {
    img: Usdc,
    curr: "USDC",
    name: "Tether",
    ids_: "tether",
    // address: "0x1F2cd0D7E5a7d8fE41f886063E9F11A05dE217Fa",
    //is our own contract address-
    address:"0x6e57F30E129e81a42ef12D72A5841E9B6b5fe877",
    decimals: "18",
  },
  {
    img: Dai,
    curr: "DAI",
    name: "Dai",
    ids_: "dai",
    // address: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60",
    //is our own contract address-
    address:"0xb6f3384dDf965D7772B87c25e221ffCd0f11576e",
    decimals: "18",
  },
  // "HUG", "ETH", "USDC", "USDT", "DAI",
];

// DAI address : 0xaD6D458402F60fD3Bd25163575031ACDce07538D
// USDC : 0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48
// USDT : 0x110a13FC3efE6A245B50102D2d79B3E76125Ae83

const discountprice = (amountToRaise, discount, maturityTime) => {
  // firstly we do overall data

  // "https://ropsten.etherscan.io/token/"+contractAddress

  // let Equations = (1+discount)^maturityTime * (amountToRaise);
  let facevalue = 1; // by default it take 1$
  // let p =facevalue*((discount)/100);
  discount = discount / 100;
  let l = Math.pow(1 + discount, maturityTime);

  let initial_price = (facevalue / l).toString();
  return Number(initial_price).toFixed(4);
};


function Graphmodal() {
  const apiCall = async () => {
    const backendAPIURL = process.env.REACT_APP_BE_API_URL;

    let url = `${backendAPIURL}api/bond/${tokenid}`;
    // let url = "http://localhost:3002/api/bond/";

    let headers = { "Content-Type": "application/json" };
    let res = await axios.get(url, { headers });
    if (res) {
      setBondDetailsApi(res.data);
      setTokenId(res.data.id);
    }
  };
  
  
  const [tokenTotalNumber, setTokenTotalNumber] = useState("");
  const [ethTotalNumber, setEthTotalNumber] = useState("");
  const [ethTotalPrice, setEthTotalPrice] = useState("");

  const location = useLocation();
  const [bondDetails, setBondDetailsApi] = useState(location.state || {});
  const [tokenid, setTokenId] = useState(bondDetails.id);

  useEffect(() => {
    apiCall();
  }, [tokenid]);

 

  // setTokenId(bondDetails.id)
  // const [tokenPrice,setTokenPrice] = useState(false)
  const endDate = new Date(bondDetails.durationOfSaleEnd);
  const startDate = new Date(bondDetails.durationOfSaleStart);
  var days =
    Number(bondDetails.maturityTime) * 365 +
    Number(bondDetails.maturityTime) / 4;
  var finalDate = new Date(
    endDate.setTime(endDate.getTime() + days * 86400000)
  );

  finalDate = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(finalDate);

  // var hourDuration = parseFloat(Math.abs(new Date() - endDate) /36e5).toFixed(2);
  // const startDate = moment(new Date());
  // const timeEnd = moment(endDate);
  // const diff = timeEnd.diff(startDate);
  // const diffDuration = moment.duration(diff);

  var tokenprice_ = discountprice(
    bondDetails.amountToRaise,
    bondDetails.discount,
    bondDetails.maturityTime
  );
  

  const [soldPercent,setSoldPercent] = useState("")

  useEffect(()=>{
    var token_Lefts = Number(bondDetails.tokenLeft);
    var token_Solds = Number(bondDetails.tokenSold);
    var calPer = (token_Solds * 100) / (token_Lefts + token_Solds);
    setSoldPercent(calPer)
  },[bondDetails,soldPercent])

 
  const defaultAccount = localStorage.getItem("address");
  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState({
    img: Dai,
    curr: "DAI",
    name: "Dai",
    ids_: "dai",
    // address: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60",
    //is our own contract address
    address:"0xb6f3384dDf965D7772B87c25e221ffCd0f11576e",
    // address: "0xD47Cbd1d6CbC61cfc34f9406a8cc71229C33555c",
    decimals: "18",
  });
  const [swapLoading, setSwapLoading] = useState(false);

  // let colletral_contractaddress = "0x47F29bBAA96E0f153119a728d9eD4F33d02416d7";
  // let colletral_contractaddress = "0xc4e6178d25eEF7511A84FC924E918c40328B264D";

  // let rops_colletral_contractaddress = "0x104305425eB979F3fBB0327C524eae33DE54b031";

  // let colletral_contractaddress = "0x67f8dDC8fD5e4e901b1DC5e0b33f22B0f2b3673C";goeril
  // let colletral_contractaddress="0x7F851cDc52A81582b43e7e5f7B5875D8e9CF057B";polygon mumbai
  let colletral_contractaddress="0xE880FA3B5C02F2c758a24529a11360fE82FaeE5F";

  // let colletral_contractaddress11 = "0x7c1B2A3cC01149629A8FFDb498A21e5682025C1a";goeril
  // let colletral_contractaddress11="0x21CFA42C5110b63b6c3b68C1e33D4df3c9A48C1f";polygon mumbai
  let colletral_contractaddress11="0x9aC8eF56DC7Ac5a99791337f7425C0104360eB48";
  
  const showModal = () => {
    setOpen(!open);
  };
  const handleSelected = (e) => {
    setSelectedToken(e);

    if (ethTotalNumber === "") {
      setEthTotalNumber(0);
    }
    // setEthTotalNumber(e);
    const coinAPI = process.env.REACT_APP_COIN_API_URL;

    axios
      .get(`${coinAPI}simple/price?ids=${e.ids_}&vs_currencies=usd`)
      .then((res) => {
        setEthTotalPrice(
          parseFloat(ethTotalNumber * res.data[e.ids_]["usd"]).toFixed(2)
        );
        var numToken = (ethTotalNumber * res.data[e.ids_]["usd"]) / tokenprice_;
        setTokenTotalNumber(parseFloat(numToken).toFixed(4));
      })
      .catch((error) => {
        console.log("Error: Non Active Token, ", error);
      });
  };

  const handleTokenValue = async (e) => {
    if (e === "") {
      e = 0;
    }
    const coinAPI = process.env.REACT_APP_COIN_API_URL;

    axios
      .get(`${coinAPI}simple/price?ids=${selectedToken.ids_}&vs_currencies=usd`)
      .then((res) => {
        var totalTokensprice = e * tokenprice_;
        var ethTotalNum = parseFloat(
          totalTokensprice / res.data[selectedToken.ids_]["usd"]
        ).toFixed(4);

        setEthTotalPrice(parseFloat(totalTokensprice).toFixed(2));
        setTokenTotalNumber(e);
        setEthTotalNumber(ethTotalNum);
      })
      .catch((error) => {
        console.log("Error: Non Active Token, ", error);
      });
  };
  const handleEthValue = async (e) => {
    if (e === "") {
      e = 0;
    }
    setEthTotalNumber(e);
    const coinAPI = process.env.REACT_APP_COIN_API_URL;
    axios
      .get(`${coinAPI}simple/price?ids=${selectedToken.ids_}&vs_currencies=usd`)
      .then((res) => {
        setEthTotalPrice(
          parseFloat(e * res.data[selectedToken.ids_]["usd"]).toFixed(2)
        );
        var numToken = (e * res.data[selectedToken.ids_]["usd"]) / tokenprice_;
        setTokenTotalNumber(parseFloat(numToken).toFixed(4));
      })
      .catch((error) => {
        console.log("Error: Non Active Token, ", error);
      });
  };
  const UpdateTokenNumber = async () => {
    const backendAPIURL = process.env.REACT_APP_BE_API_URL;
    var swappedDict = {
      'eth' :0 ,
      'dai' :0 ,
      'usdc':0 ,
      'usdt':0 ,
    }
    console.log("selectedToken.curr",selectedToken.curr);
    if (selectedToken.curr === 'DAI'){

      console.log("D",(parseFloat(bondDetails.swapped_amount.dai) + parseFloat(ethTotalNumber)).toFixed(4));
      console.log("T",typeof parseFloat((parseFloat(bondDetails.swapped_amount.dai) + parseFloat(ethTotalNumber)).toFixed(4)));


      swappedDict = {
        'eth' :parseFloat(bondDetails.swapped_amount.eth).toFixed(4) ,
        'dai' :(parseFloat(bondDetails.swapped_amount.dai) + parseFloat(ethTotalNumber)).toFixed(4),
        'usdc':parseFloat(bondDetails.swapped_amount.usdc).toFixed(4) ,
        'usdt':parseFloat(bondDetails.swapped_amount.usdt).toFixed(4) ,
      }
    }
    else if (selectedToken.curr === 'USDC'){
      swappedDict = {
        'eth' :parseFloat(bondDetails.swapped_amount.eth).toFixed(4) ,
        'dai' :parseFloat(bondDetails.swapped_amount.dai).toFixed(4) ,
        'usdc':parseFloat(Number(bondDetails.swapped_amount.usdc) + parseFloat(ethTotalNumber)).toFixed(4),
        'usdt':parseFloat(bondDetails.swapped_amount.usdt).toFixed(4),
      }
    }
    else if (selectedToken.curr === 'USDT'){
      swappedDict = {
        'eth' :parseFloat(bondDetails.swapped_amount.eth).toFixed(4) ,
        'dai' :parseFloat(bondDetails.swapped_amount.dai).toFixed(4) ,
        'usdc':parseFloat(bondDetails.swapped_amount.usdc).toFixed(4) ,
        'usdt':parseFloat(Number(bondDetails.swapped_amount.usdt) + parseFloat(ethTotalNumber)).toFixed(4),
      }
    }
    else if (selectedToken.curr === 'ETH'){
      swappedDict = {
        'eth' :parseFloat(Number(bondDetails.swapped_amount.eth) + parseFloat(ethTotalNumber)).toFixed(4),
        'dai' :Number(bondDetails.swapped_amount.dai) ,
        'usdc':Number(bondDetails.swapped_amount.usdc) ,
        'usdt':Number(bondDetails.swapped_amount.usdt) ,
      }
    }
    
    console.log("updateData",parseFloat(bondDetails.tokenLeft).toFixed(4) - parseFloat(tokenTotalNumber).toFixed(4));

    let url = `${backendAPIURL}api/bond/${bondDetails.id}`;
    // let url = "http://localhost:3002/api/bond/deploy_token";
    let headers = { "Content-Type": "application/json" };

    const updateData = {
      tokenLeft: 
        parseFloat(parseFloat(bondDetails.tokenLeft) - parseFloat(tokenTotalNumber)).toFixed(4),
      tokenSold: (
        parseFloat(bondDetails.tokenSold) + parseFloat(tokenTotalNumber)).toFixed(4)
      ,
      swapped_amount: swappedDict
    };
    
    await axios
      .put(url, updateData, { headers })
      .then((res) => {
        apiCall();
      })
      .catch((e) => {
        console.log("EROOR --- ", e);
      });
  };
  const swapTToken = async () => {
    let web3;
    setSwapLoading(true);
    if (selectedToken.curr === "ETH") {

      console.log("Get ETH and send b-token");
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();

      // web3 = new Web3(window.ethereum && window.web3.currentProvider);
      window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      let macc = accounts[0];

      const ethTokenSwap = new web3.eth.Contract(
        contract_abi,
        colletral_contractaddress
      );
      
      const decimal_val = Math.pow(10, Number(18));
      const tokenTotalNumber_ = Number(tokenTotalNumber * decimal_val);
      const ethTotalNumber_ = web3.utils.toWei(ethTotalNumber, "ether");
      console.log(bondDetails.contractAddress,
        String(ethTotalNumber_),
        String(tokenTotalNumber_),
        ethTotalNumber);

      ethTokenSwap.methods
        .ethSwap(
          bondDetails.contractAddress,
          String(ethTotalNumber_),
          String(tokenTotalNumber_)
        )
        .send({
          from: macc,
          gas: "1420000",
          gasPrice: "1500000000",
          value: web3.utils.toWei(ethTotalNumber + 1, "ether"),
        })
        .on("transactionHash", function (hash) {
          console.log(hash, "hashtransaction here ");
        })
        .then(function () {
            const fundTrans=new web3.eth.Contract(
                    contract_abi1,
                    colletral_contractaddress11
                  );
                  const weiValue="3787140000000000";
                  const amount  ="3787140000000000";
                  fundTrans.methods.deposit(macc,amount).send(
                    {from: macc,to :contract_abi1, 
                      gas: "1420000", gasPrice: "1500000000",value:weiValue})
                  .then(function () {
                    console.log("Fund Transfer Successfully");
                    toast("Successfully ");
                    UpdateTokenNumber();
                    setSwapLoading(false);
                    apiCall();
                    setEthTotalNumber("");
                    setTokenTotalNumber("");
                    setEthTotalPrice("");
                  })
                  .catch((e) => {
                    toast("Failed. Please do check!");
                    setSwapLoading(false);
                  });
        })
        .catch((e) => {
          toast("Token Swapping Failed. Please do check!");
          setSwapLoading(false);
        });

    } else {
      console.log("Get Dai or USDT Token and send b-token");

      if (window.ethereum && window.ethereum.isMetaMask) {
        // let colletral_contractaddress =
        //   "0xc4e6178d25eEF7511A84FC924E918c40328B264D";
        // let colletral_contractaddress = "0x47F29bBAA96E0f153119a728d9eD4F33d02416d7";
        // let rops_colletral_contractaddress = "0x104305425eB979F3fBB0327C524eae33DE54b031";

        // let colletral_contractaddress = "0x67f8dDC8fD5e4e901b1DC5e0b33f22B0f2b3673C";goeril
        // let colletral_contractaddress="0x7F851cDc52A81582b43e7e5f7B5875D8e9CF057B";polygon mumbai
        let colletral_contractaddress="0xE880FA3B5C02F2c758a24529a11360fE82FaeE5F";
        web3 = new Web3(window.ethereum && window.web3.currentProvider);

        console.log("1");
        window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        let macc = accounts[0];
        console.log("2",selectedToken.address);

        const tokenInst = new web3.eth.Contract(abi, selectedToken.address);
        console.log("2.1", abi)
        var balance = await tokenInst.methods.balanceOf(macc).call();

        console.log("3");

        if (Number(balance) >= Number(ethTotalNumber)) {
          let contract = new web3.eth.Contract(abi, selectedToken.address);
          const decimal_val = Math.pow(10, Number(selectedToken.decimals));
          const tprice = Number(ethTotalNumber * decimal_val);
          console.log("4");

          contract.methods
            .transfer(colletral_contractaddress, String(tprice))
            .send({ from: macc, gas: "1420000", gasPrice: "17780000000" })
            .on("transactionHash", function (hash) {
              console.log(hash, "hashtransaction here ");
            })
            .then(function () {
              console.log("5");

              // const tokenTotalNumber_ = web3.eth.toWei(tokenTotalNumber, 'ether');
              // let contract_ = new web3.eth.Contract(abi, bondDetails.contractAddress);

              const ethTokenSwap = new web3.eth.Contract(
                contract_abi,
                colletral_contractaddress
              );
              // const decimals =  contract.methods.decimals().call;
              const decimal_val = Math.pow(10, Number(18));
              const tokenTotalNumber_ = Number(tokenTotalNumber * decimal_val);
              ethTokenSwap.methods
                .tokenSwap(
                  bondDetails.contractAddress,
                  String(tokenTotalNumber_)
                )
                .send({ from: macc, gas: "1420000", gasPrice: "17780000000" })
                .on("transactionHash", function (hash) {
                  console.log(hash, "hashtransaction here ");
                })
                .then(function () {
                  const fundTrans=new web3.eth.Contract(
                    contract_abi1,
                    colletral_contractaddress11
                  );
                  const weiValue="3787140000000000";
                  const amount  ="3787140000000000";
                  fundTrans.methods.deposit(macc,amount).send(
                    {from: macc,to :contract_abi1, 
                      gas: "1420000", gasPrice: "17780000000",value:weiValue})
                  .then(function () {
                    console.log("Fund Transfer Successfully");
                    toast("Successfully ");
                    UpdateTokenNumber();
                    setSwapLoading(false);
                    apiCall();
                    setEthTotalNumber("");
                    setTokenTotalNumber("");
                    setEthTotalPrice("");
                  })
                  .catch((e) => {
                    toast("Failed. Please do check!");
                    setSwapLoading(false);
                  });
                })
                .catch((e) => {
                  setSwapLoading(false);
                  toast("Token Swapping Failed. Please do check!");
                });
            })
            .catch((e) => {
              setSwapLoading(false);
              toast("Token Swapping Failed. Please do check!");
            });

        } else {
          toast("Token Swapping Failed. No enough Token to transfer!!");
          setSwapLoading(false);
        }
      }
    }
  };
  return (
    <>
      <Topbar address={defaultAccount.replace(/"/g, "")} />
      <Container className="graph-cont">
        <div className="justify-content-center">
          <Card className="card-main">
            <Row>
              <Col lg={9}>
                <div className="warningup-text warningup-text2">
                  <div className="row">
                    <div className="col-lg-1 col-sm-1">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="exclamation-circle"
                        className="svg-inline--fa fa-exclamation-circle fa-w-16 "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                        ></path>
                      </svg>
                    </div>
                    <div className="warning_head col-lg-11 col-sm-11">
                      <h1>Warning</h1>
                      <p>
                        The auctions in this list are indexed directly from the
                        blockchain and do not represent an endorsement by the
                        Hug platform or the Hug community. Please note that
                        participating in any of these auctions is a high-risk
                        endeavor and that the value of the tokens that you've
                        received in exchange for contributing to such an auction
                        might go to 0. Hug is not liable for any losses incurred
                        by using our platform.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="main-chart">
                  <div className="chart-text"></div>
                  <div className="chart-table">
                    <h1>
                      <img
                        src={bondDetails.tokenImage}
                        alt="icon"
                        className="dai-img"
                      />
                      <span>b</span>
                      {bondDetails.tokensymbol}
                    </h1>
                    {/* <Table striped bordered hover size="sm"> */}
                    <Table responsive>
                      <tbody className="chart-tabbody">
                        <tr>
                          <td>Maturity Date </td>
                          <td>Token Sale Time </td>
                          <td>Total Volume</td>
                          {/* <td>Interest Rate</td> */}
                          <td>% of tokens sold</td>
                        </tr>
                        <tr className="tfooetr">
                          <td>{finalDate}</td>
                          <td>{startDate.toLocaleTimeString("en-US")}</td>
                          <td>{Math.round(parseFloat(bondDetails.tokenLeft) + parseFloat(bondDetails.tokenSold)) } b{bondDetails.tokensymbol}</td>
                          {/* <td>$0.000124</td> */}
                          <td>
                            {parseFloat(soldPercent).toFixed(2)}%<br />
                            <span>${bondDetails.amountToRaise}</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Table responsive>
                      <tbody className="chart-tabbody">
                        <tr>
                          <td>Face Value</td>
                          <td>Price</td>
                          <td>Current Discount Rate</td>
                          <td>Collateralization Rate</td>
                        </tr>
                        <tr className="tfooetr">
                          <td> $1.00</td>
                          <td>${tokenprice_}</td>
                          <td>{bondDetails.discount}%</td>
                          <td>{bondDetails.collateralPercent}%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="chart-mian">
                    <div className="launch-dics">
                      <span>Description of launch</span>
                      <p>{bondDetails.descriptionOfLaunch}</p>
                    </div>
                    <div className="launch-dics">
                      <span>Revenues</span>
                      <p>{bondDetails.Revenues}</p>
                    </div>
                    <div className="launch-dics">
                      <a
                        href={bondDetails.LearnMoreLink}
                        rel="noreferrer"
                        className="generated"
                        target="_blank"
                      >
                        Learn More{" "}
                        <img src={arrow} alt="icon" className="dai-img" />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="right-currence">
                  <Row>
                    <Col lg={6} sm={6}>
                      <div className="inputwap">
                        <input
                          type="number"
                          label="Bonded Token"
                          className="palcing form-control"
                          placeholder="0"
                          min="0"
                          onChange={(event) =>
                            handleEthValue(event.target.value)
                          }
                          value={ethTotalNumber}
                        />
                        <label className="doller">~${ethTotalPrice}</label>
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className="inputwap2">
                        <Button className="DAi" onClick={() => showModal()}>
                          <img
                            src={selectedToken.img}
                            alt="icon"
                            className="dai-img"
                          />
                          <span>{selectedToken.curr}</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} sm={6}>
                      <div className="inputwap">
                        <input
                          type="number"
                          label="Bonded Token"
                          className="palcing form-control"
                          min="0"
                          max={Number(bondDetails.tokenLeft).toFixed(4)}
                          placeholder="0"
                          onChange={(event) =>
                            handleTokenValue(event.target.value)
                          }
                          value={tokenTotalNumber}
                        />
                        <label className="doller">~${ethTotalPrice}</label>
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className="inputwap2">
                        <p className="bMC">b{bondDetails.tokensymbol}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="Swap">
                      {tokenTotalNumber === "" && ethTotalNumber === "" ? (
                        <Button
                          variant="outlined"
                          className="Swap"
                          disabled={true}
                          onClick={() => swapTToken()}
                        >
                          Swap{" "}
                        </Button>
                      ) : swapLoading ? (
                        <Button
                          variant="outlined"
                          className="Swap"
                          disabled={swapLoading}
                          onClick={() => swapTToken()}
                        >
                          <img
                            src={loadinggif}
                            alt="Happy Emoticons"
                            className="Swaploading"
                          />
                        </Button>
                      ) : (
                        <>
                          <Button
                            variant="outlined"
                            className="Swap"
                            disabled={swapLoading}
                            onClick={() => swapTToken()}
                          >
                            Swap{" "}
                          </Button>
                        </>
                      )}

                      {/* <Button variant="outlined" className="Swap" onClick={() => swapToken()}>Swap </Button> */}
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
          <PopupModal
            open={open}
            data={skills}
            handleHide={() => showModal()}
            onSelect={(e) => handleSelected(e)}
          />
        </div>
      </Container>
    </>
  );
}

export default Graphmodal;
