import React, { useState, useEffect } from "react";
import Topbar from "./Topbar/topbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, Card, Button, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import { Container } from "react-bootstrap";
import "./Bond.css";
import { Container } from "react-bootstrap";
import axios from "axios";
// import vector from "../assets/images/Vector.png";
// import icn from "../assets/images/jalebi.png";
import gicon from "../assets/images/greenRect.png";
import reddoticon from "../assets/images/red_dot.png";
import Web3 from "web3";
import daicon from "../assets/images/DAI-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import abi from "erc-20-abi";
import loadinggif from "../../src/assets/images/loader.gif";
// import { ethers } from "ethers";
import Vector from "../../src/assets/images/vectorblue.png";
// import Usdc from "../../src/assets/images/usdc.png";
import Usdt from "../../src/assets/images/usdt.png";
import Dai from "../../src/assets/images/dai.png";
import Usdc from "../../src/assets/images/usdc.png";
import mini_abi from "./JSON/mini_abi1.json";
function Bonds() {
  // const defaultAccount = localStorage.getItem("address");
  const [repo, setData] = useState([]);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withDrawReload, setWithDrawReload] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(""); // set account address for this
  // const [userBalance, setUserBalance] = useState(null); // set balance of any address
  // const [connButtonText, setConnButtonText] = useState("CONNECT WALLET"); // connet wallet through this
  // const [selectNetwork, setSelectNetwork] = useState(""); // Get the network from wallet address
  var allEmpty = true;
  var connButtonText = "CONNECT WALLET"
  let skills = [
    {
      img: Vector,
      curr: "ETH",
      name: "Ethereum",
      ids_: "ethereum",
      address: ""
    },
    {
      img: Usdt,
      curr: "USDT",
      name: "Tether",
      ids_: "tether",
      // address: "0x5a1091C846db74571994FD7DA9851d5D6798dB70",
      //is our own contract address-
      address:"0xf6972F489b08652e67Dadb01D5186bBebf67e699",
      decimals: "6",
    },
    {
      img: Usdc,
      curr: "USDC",
      name: "Tether",
      ids_: "tether",
      // address: "0x1F2cd0D7E5a7d8fE41f886063E9F11A05dE217Fa",
      //is our own contract address-
      address:"0x6e57F30E129e81a42ef12D72A5841E9B6b5fe877",
      decimals: "6",
    },
    {
      img: Dai,
      curr: "DAI",
      name: "Dai",
      ids_: "dai",
      // address: "0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60",
      //is our own contract address-
      address:"0xb6f3384dDf965D7772B87c25e221ffCd0f11576e",
      decimals: "18",
    },
    // "HUG", "ETH", "USDC", "USDT", "DAI",
  ];

  const navigate = useNavigate();

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          localStorage.setItem("address", JSON.stringify(result[0]));
          // setSelectNetwork(window.ethereum.networkVersion);
          setTimeout(() => {
            navigate("/bonds");
          }, 1000);
        });
    } else {
      // for install wallet first then connect to this site
      toast("Install Metamask");
    }
  };

  const accountChangedHandler = (newAccount) => {
    // functioanlity  to change  address or switch to another account
    setDefaultAccount(newAccount);
    console.log("working", defaultAccount, localStorage.getItem("address"))
    // getUserBalance(newAccount.toString());
  };
  // const getUserBalance = (address) => {
  //   window.ethereum
  //     .request({ method: "eth_getBalance", params: [address, "latest"] })
  //     .then((balance) => {
  //       setUserBalance(ethers.utils.formatEther(balance));
  //     });
  // };

  async function isMetaMaskConnected() {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    console.log(accounts, "accounts")
    if (accounts && accounts.length > 0) {
      console.log("connected")
      localStorage.setItem("address",accounts[0])
    } else {
      console.log("disconnected")
      localStorage.removeItem("address")
    }
    // return accounts && accounts.length > 0;
  }

  
  isMetaMaskConnected()
 

  //============= this function is used for getting Api data.===============

  const apiCall = async () => {
    const backendAPIURL = process.env.REACT_APP_BE_API_URL;

    let url = `${backendAPIURL}api/bond/`;
    // let url = "http://localhost:3002/api/bond/";

    let headers = { "Content-Type": "application/json" };
    let res = await axios.get(url, { headers });
    if (res) {
      setData(res.data);
      console.log("DATA SET")
    }
  };
  // let web3;
  const updateWithdraw = async (bondItemId) => {
    const backendAPIURL = process.env.REACT_APP_BE_API_URL;

    let url = `${backendAPIURL}api/bond/${bondItemId}`;
    // let url = "http://localhost:3002/api/bond/deploy_token";
    let headers = { "Content-Type": "application/json" };
    const updateData = {
      isWithdraw: true,
      tokenLeft: "0",
      swapped_amount:{'eth':0,'dai':0,'usdc':0,'usdt':0}
    };
    await axios
      .put(url, updateData, { headers })
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log("EROOR --- ", e);
      });
  };
  
  const handleWithDraw = async (item) => {
    console.log("withdraw", item);
    setWithdrawLoading(item.id);

    if (window.ethereum && window.ethereum.isMetaMask) {
      const web3 = new Web3(window.ethereum);

      const accounts = await web3.eth.getAccounts();
      let macc = accounts[0];

      // let rops_colletral_contractaddress = "0x104305425eB979F3fBB0327C524eae33DE54b031";

      //let colletral_contractaddress = "0x67f8dDC8fD5e4e901b1DC5e0b33f22B0f2b3673C";goeril
      // let colletral_contractaddress="0x7F851cDc52A81582b43e7e5f7B5875D8e9CF057B";polygon mumbai
      let colletral_contractaddress="0xE880FA3B5C02F2c758a24529a11360fE82FaeE5F";

      const tokenInst = new web3.eth.Contract(mini_abi, colletral_contractaddress);
      const debtToken = new web3.eth.Contract(abi, item.contractAddress);
      
      let decimals = await debtToken.methods.decimals().call();


      function tokenAmountToWei(amount, decimals) {
        return web3.utils.toBN("0x" + (amount * 10 ** decimals).toString(16)).toString();
      }

      const tokenNum = tokenAmountToWei(item.tokenLeft,decimals)

      console.log("tokenNum",tokenNum);
     
    
      const tokenDaiNum = (parseFloat(item.swapped_amount.dai) * Math.pow(10, Number(18)));
      const tokenUsdcNum = (parseFloat(item.swapped_amount.usdc) * Math.pow(10, Number(6)));
      const tokenUsdtNum = ( parseInt(item.swapped_amount.usdt)* Math.pow(10, Number(decimals)));

      console.log("ethNum",item.swapped_amount);
      const ethNum = (web3.utils.toWei(String(item.swapped_amount.eth), 'ether'));
      
      console.log("tokenDaiNum",tokenDaiNum);
      if (tokenUsdtNum) {
        
        tokenInst.methods
          .withdrawToken_3(
            item.contractAddress,
            String(tokenNum),
            skills[3].address,
            String(tokenDaiNum),
            skills[2].address,
            String(tokenUsdcNum),
            String(ethNum),
          )
          .send({
            from: macc,
            gas: "1420000",
            gasPrice: "17780000000",
          })
          .on("transactionHash", function (hash) {
            console.log(hash, "hashtransaction here ");
          })
          .then(function () {

            const respDatapdate = updateWithdraw(item.id)
            console.log("respDatapdate", respDatapdate)
            toast("Token Withdraw is successfully.");
            console.log("ABCD")
            setWithdrawLoading(false);
            setTimeout(() => {
              setWithDrawReload(true);
            }, 1000);
            
            apiCall();
            

          })
          .catch((e) => {
            toast("Token Withdraw Failed. Please try again latter!");
            console.log("Errors",e)
            setWithdrawLoading(false);

          });
      }
      else {
        console.log(" NO tokenUsdcNum",tokenUsdcNum);
        tokenInst.methods
          .withdrawToken(
            item.contractAddress,
            String(tokenNum),
            skills[3].address,
            String(tokenDaiNum),
            skills[2].address,
            String(tokenUsdcNum),
            skills[1].address,
            String(tokenUsdtNum),
            String(ethNum),
          )
          .send({
            from: macc,
            gas: "1420000",
            gasPrice: "17780000000",
          })
          .on("transactionHash", function (hash) {
            console.log(hash, "hashtransaction here ");
          })
          .then(function () {

            const respDatapdate = updateWithdraw(item.id)
            console.log("respDatapdate", respDatapdate)
            toast("Token Withdraw is successfully.");
            console.log("ABCD2---")
            setWithdrawLoading(false);
            
            setTimeout(() => {
              setWithDrawReload(true);
            }, 1000);

          })
          .catch((e) => {
            toast("Token Withdraw Failed. Please try again latter!");
            setWithdrawLoading(false);
            console.log("Error -",e)
          });
      }
    }}
  const handleBuy = (item) => {
    navigate("/bargraph", { state: item });
  };
  //=============this function is used for price logic data====================
  const discountprice = (amountToRaise, discount, maturityTime) => {
    // firstly we do overall data

    // "https://ropsten.etherscan.io/token/"+contractAddress

    // let Equations = (1+discount)^maturityTime * (amountToRaise);
    let facevalue = 1; // by default it take 1$
    // let p =facevalue*((discount)/100);
    discount = discount / 100;
    let l = Math.pow(1 + discount, maturityTime);

    let initial_price = (facevalue / l).toString();
    // let initial_price = (facevalue/(1+(discount))**maturityTime).toString();
    // return(Number(initial_price.slice(0, (initial_price.indexOf("."))+3)));
    return Number(initial_price).toFixed(4);
  };

  useEffect(() => {
    console.log("First Runn")
    apiCall();
  }, [withDrawReload]);

  //=====this function is used for getting to show end and begin day through start and end schedul date =======
  const end_begin_time = (startDate, endDate) => {
    let current_date = new Date();
    let starts = new Date(startDate);
    if (current_date >= starts) {
      // const date1 =new Date(startDate);//current date
      const date1 = new Date();
      const date2 = new Date(endDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return " Ends in " + diffDays + " days";
    } else {
      const date1 = new Date(); //current date
      const date2 = new Date(startDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return " Begin in " + diffDays + " days";
    }
  };
  const noDataPreview = "No Data to Preview"
  const [isClickedWithdrawBtn, setIsClickedWidthdrawnBtn] = useState(false);
  return (
    <>
      <Topbar address={defaultAccount.replace(/"/g, "")} />
      {/* <Topbar/> */}
      {localStorage.getItem("address") !== null ?
        <Container>
          <Row className="justify-content-center">
            <Col lg={9} sm={9}>
              <Form className="mt-5 ">
                <Card className="form-one mt-4">
                  <div className="bond-card">
                    <Row>
                      <Col lg={6} sm={6}>
                        <div className="vw_bond">View Bond Sales</div>
                      </Col>
                      <Col lg={6} sm={6}>
                        <div className="Bond2">
                          {/* <Button color="primary float-right continue-btn" >Create Bond</Button> */}
                          {/* <a href="/form" className="form-btn">Create Bond</a> */}
                          <Link to="/form" className="form-btn">
                            Create Bond
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="warning-div">
                    <div className="warningup-text">
                      <Row>
                        <Col lg={1} sm={1}>
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        </Col>
                        <Col lg={11} sm={11} className="warning_head">
                          <h1>Warning</h1>
                          <p>
                            The auctions in this list are indexed directly from
                            the blockchain and do not represent an endorsement by
                            the Hug platform or the Hug community. Please note
                            that participating in any of these auctions is a
                            high-risk endeavor and that the value of the tokens
                            that you've received in exchange for contributing to
                            such an auction might go to 0. Hug is not liable for
                            any losses incurred by using our platform.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="table-div">
                    <div className="table-head">
                      <Table size="sm">
                        <thead className="boder-wap">
                          <tr>
                            <th>Token</th>
                            <th>Network</th>
                            <th>Status</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="bagraund-wap">
                          {repo.length !== 0 ? [repo.map(
                            (item) => {
                              if (new Date(item.durationOfSaleEnd) >= new Date()) {
                                // setAllEmpty(false)
                                allEmpty = false
                                return (
                                  <tr
                                    key={item.contractAddress}
                                    className="main-box"
                                  >
                                    <td className="firstbox">
                                      <a
                                        href={`${item.blockUrls}/token/${item.contractAddress}`}
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        <img
                                          src={item.tokenImage}
                                          alt="icon"
                                          className="ic-img2 ic-img3"
                                        />
                                        b{item.tokensymbol}
                                      </a>
                                    </td>

                                    <td className="text-center">
                                      <img
                                        src={item.networkIcon}
                                        alt=""
                                        className="ic-img"
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={gicon}
                                        alt="icon"
                                        className="ic-img2"
                                      />
                                      {end_begin_time(
                                        item.durationOfSaleStart,
                                        item.durationOfSaleEnd
                                      )}
                                    </td>
                                    {/* <td><img src={gicon} alt="icon" className="ic-img2"/>date</td> */}
                                    <td>
                                      <img
                                        src={daicon}
                                        alt="icon"
                                        className="ic-img2"
                                      />
                                      {discountprice(
                                        item.amountToRaise,
                                        item.discount,
                                        item.maturityTime
                                      )}
                                    </td>

                                    {/* <td className="buy lastbox"><a href="#" className="buy-button">buy</a></td> */}
                                    <td className="buy lastbox">

                                      <button
                                        className={new Date(item.durationOfSaleStart) >= new Date() ? "buy-button disable" : "buy-button"}
                                        onClick={() => handleBuy(item)}
                                        disabled={new Date(item.durationOfSaleStart) >= new Date() ? true : false}
                                      >
                                        buy
                                      </button>
                                    </td>

                                    {/* <td className="buy lastbox"><Button color="primary float-right continue-btn" className="buy-button" >Buy</Button></td> */}
                                  </tr>
                                )
                              }
                              else if ((localStorage.getItem("address").replace(/"/g, "").includes(item.ownerAddress.toLowerCase())) && (new Date(item.durationOfSaleEnd) < new Date())) {
                                allEmpty = false
                                
                                if (item.isWithdraw === false) {
                                  return (
                                    <tr
                                      key={item.contractAddress}
                                      className="main-box"
                                    >
                                      <td className="firstbox">
                                        <a
                                          href={`${item.blockUrls}/token/${item.contractAddress}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "black",
                                          }}
                                          target={"_blank"} rel="noreferrer"
                                        >
                                          <img
                                            src={item.tokenImage}
                                            alt="icon"
                                            className="ic-img2 ic-img3"
                                          />
                                          b{item.tokensymbol}
                                        </a>
                                      </td>

                                      <td className="text-center">
                                        <img
                                          src={item.networkIcon}
                                          alt=""
                                          className="ic-img"
                                        />
                                      </td>
                                      <td>
                                        <img
                                          src={reddoticon}
                                          alt="icon"
                                          className="ic-img2"
                                        />
                                        {"Sale has Ended"}
                                      </td>
                                      {/* <td><img src={gicon} alt="icon" className="ic-img2"/>date</td> */}
                                      <td>
                                        <img
                                          src={daicon}
                                          alt="icon"
                                          className="ic-img2"
                                        />
                                        {discountprice(
                                          item.amountToRaise,
                                          item.discount,
                                          item.maturityTime
                                        )}
                                      </td>

                                      {/* <td className="buy lastbox"><a href="#" className="buy-button">buy</a></td> */}
                                      <td className="buy lastbox">{withdrawLoading === item.id ? (

                                        <button
                                          className="withdraw-button"
                                          
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleWithDraw(item);
                                          }}
                                        >
                                          <img
                                            src={loadinggif}
                                            alt="Happy Emoticons"
                                            className="withDrawloading"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          className="withdraw-button"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleWithDraw(item);
                                            setIsClickedWidthdrawnBtn(true);
                                          }}
                                          disabled={isClickedWithdrawBtn}
                                        >
                                          Withdraw{" "}
                                        </button>
                                      )
                                      }
                                      </td>

                                      {/* <td className="buy lastbox"><Button color="primary float-right continue-btn" className="buy-button" >Buy</Button></td> */}
                                    </tr>
                                  )
                                }
                                else {
                                  return (<></>)
                                }
                              }
                              return ""
                            }
                             
                            
                          ),allEmpty === true&&<tr><td className="empty"><div className="empty-list"align="center">{noDataPreview}</div></td></tr>]:<tr><td className="empty"><div className="empty-list"align="center">{noDataPreview}</div></td></tr>}
                        </tbody>
                        {/* <tbody className="bagraund-wap">
                        <tr>
                          <td><img src={icn} alt="icon" className="ic-img2"/>dMC</td>
                          <td className="text-center"><img src={vector} alt="icon" className="ic-img"/></td>
                          <td><img src={gicon} alt="icon" className="ic-img2"/>Ends in 4.7 days</td>
                          <td><img src={daicon} alt="icon" className="ic-img2"/>0.27</td>
                          <td className="buy"><a href="#" className="buy-button">buy</a></td>
                        </tr>
                      </tbody> */}
                        {/* <tbody className="bagraund-wap">
                        <tr>
                          <td><img src={icn} alt="icon" className="ic-img2"/>dMC</td>
                          <td className="text-center"><img src={vector} alt="icon" className="ic-img"/></td>
                          <td><img src={gicon} alt="icon" className="ic-img2"/>Begins in 2.7 days</td>
                          <td><img src={daicon} alt="icon" className="ic-img2"/>0.27</td>
                          <td className="buy"><a href="#" className="buy-button">buy</a></td>
                        </tr>
                      </tbody> */}
                      </Table>
                    </div>
                  </div>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        :
        <div className="wallet-btn-div">
          <Button
            onClick={connectWalletHandler}
            variant="outlined"
            className="connct-wallet-btn"
          >
            {connButtonText}
          </Button>
          {/* <div>{defaultAccount}</div>
          <div>{userBalance}</div> */}
        </div>
      }
    </>
  );
}

export default Bonds;
