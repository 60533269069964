import React, {  useState } from "react";
import {
  Navbar,
  //Button, NavbarBrand, Form, FormControl,
  Offcanvas,
  Nav,
  Container,
  Button,
} from "react-bootstrap";
// import Image from 'next/image'
import ilink from "../../assets/images/ilink.png";
// import { Link, NavLink } from "react-router-dom";
import hug from "../../assets/images/hug.png";
// import { faAddressBook } from "@fortawesome/fontawesome-free-regular";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ilink from '../../assets/images/ilink.png'

const Topbar = (props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [address, setAddress] = useState('')
  // const [showModal, setShowModal] = useState(false)

  // const disconnectWallet = () => {
  //   setShowModal(false);
  //   localStorage.setItem("address", "");
  // }
  // const handleCloseModal = () => {
  //   setShowModal(false);
  // }

  // const [reRender,setRerender] = useState('');

  window.ethereum.on("accountsChanged", async () => {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: "eth_accounts" });
    let oldAccount = localStorage.getItem("address");
    if (accounts && oldAccount !== accounts[0] && !(oldAccount === "" && accounts[0] === undefined)) {
      if (accounts && accounts.length > 0) {

        localStorage.setItem("address", accounts[0]);
        defaultAccount = accounts[0]
        
        toast("Account Switched...");
        // console.log({reRender});
        // setRerender(true)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
        
        // console.log("Dekh le akash chal rha h");

      } else {
        localStorage.setItem("address", "");
        toast("Account Disconnected...");
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      }
    }
  });

  let defaultAccount = ""

  if (localStorage.getItem("address") != null) {
    defaultAccount = localStorage.getItem("address").replace(/"/g, "");
    //     setAddress(defaultAccount)
    //     // window. location. reload()
    
      }
  

  // useEffect(() => {
  //   console.log(reRender)
   
  // },[reRender])
  

  // console.log( "address", defaultAccount)

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
        <Container>
          <Navbar.Brand href={"/home"} style={{ letterSpacing: 2 }}>
            <img src={hug}  alt="Logo" />
          </Navbar.Brand>
          <Button
            variant="outlined"
            className="topbar-btn connct-wallet-btn mobileview"
          >
            {defaultAccount}
            <img src={ilink} alt="ilink" className="btn-img" />
          </Button>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* <Nav className="me-auto">
      <Nav.Link href="#features">Features</Nav.Link>
      <Nav.Link href="#pricing">Pricing</Nav.Link>
      <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown>
    </Nav> */}

            <Nav placement="justify-content-end">
              <Nav.Link>
                <Button
                  variant="outlined"
                  className="topbar-btn connct-wallet-btn"
                >
                  {defaultAccount}
                  {/* <div>
    <Image
      src='../../assets/images/ilink.png'
      alt="Picture of the author"
      width="10"
      height="10"
      /> </div> */}
                  <img src={ilink} alt="ilink" className="btn-img" />
                </Button>
              </Nav.Link>
              <Nav.Link href="/bonds">BONDS</Nav.Link>
              {/* <Nav.Link eventKey={2} href={"/topbutton"}>
                DEX
              </Nav.Link> */}
              <Navbar.Toggle
                aria-controls="offcanvasNavbar"
                className="main-toggler"
                onClick={handleShow}
              />

              <Offcanvas
                show={show} onHide={handleClose} {...props}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="offcanvasNavbarLabel">
                    BONDIE
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href={"/home"}>Home</Nav.Link>
                    <Nav.Link href={"/bonds"}>Bond</Nav.Link>
                    {/* <NavDropdown title="Dropdown" id="offcanvasNavbarDropdown">
            <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              Something else here
            </NavDropdown.Item>
          </NavDropdown> */}
                  </Nav>
                </Offcanvas.Body>
              </Offcanvas>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      
    </>
  );
}

export default Topbar;