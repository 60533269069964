import React, { useEffect, useState } from "react";
// import { ethers } from "ethers";
import Topbar from "./Topbar/topbar";
// import Bonds from "../components/Bond";
import "./Topbar/topbar.css";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { propTypes } from "react-bootstrap/esm/Image";
// import homeloader from "../../src/assets/images/homeloader.gif";

// import MasterForm from ".";
// const getLocalItems = () => {
//   let defaultAccount = localStorage.getItem("address");

//   // return localStorage.getItem("address")
// };
function Home(props) {
  // localStorage.setItem('adddress', 'defaultaddress');
  // const [item,setItem]=useState(getLocalItems);

  // here is a functionality of connect wallet
  // const [navigate,useNavigate]=useState(null);
  let navigate = useNavigate();
  // const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(); // set account address for this
  // const [userBalance, setUserBalance] = useState(null); // set balance of any address
  // const [connButtonText, setConnButtonText] = useState("CONNECT WALLET"); // connet wallet through this
  // const [selectNetwork, setSelectNetwork] = useState(""); // Get the network from wallet address

  const connButtonText = "CONNECT WALLET";

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          localStorage.setItem("address", JSON.stringify(result[0]));
          // setSelectNetwork(window.ethereum.networkVersion);
          setTimeout(() => {
            navigate("/bonds");
          }, 1000);
        });
    } else {
      // for install wallet first then connect to this site
      toast("Install Metamask");
    }
  };

  const accountChangedHandler = (newAccount) => {
    // functioanlity  to change  address or switch to another account
    setDefaultAccount(newAccount);
    // getUserBalance(newAccount.toString());
  };
  // const getUserBalance = (address) => {
  //   window.ethereum
  //     .request({ method: "eth_getBalance", params: [address, "latest"] })
  //     .then((balance) => {
  //       setUserBalance(ethers.utils.formatEther(balance));
  //     });
  // };
  window.ethereum.on("accountsChanged", accountChangedHandler);

  // add data to localStorage

  useEffect(() => {
    window.ethereum.on("chainChanged", () => {
      window.location.reload();
    });
    if (localStorage.getItem("address")) {
      setDefaultAccount(localStorage.getItem("address").split('"')[1]);
    }

    localStorage.setItem("networkId", window.ethereum.networkVersion);
  }, [defaultAccount]);

  return (
    <>
      {/* <img
          src={homeloader}
          alt="Happy Emoticons"
          className="demoimg" 
          /> */}

      <Container className="Check">
        {""}

        <Topbar address={defaultAccount} />

        <div className="wallet-btn-div">
          <Button
            onClick={connectWalletHandler}
            variant="outlined"
            className="connct-wallet-btn"
          >
            {connButtonText}
          </Button>
          {/* <div>{defaultAccount}</div>
          <div>{userBalance}</div> */}
        </div>
        {/* <MasterForm/> */}
      </Container>
    </>
  );
}

export default Home;
