import React, { useState, useEffect } from "react";
import Web3 from "web3";
import axios from "axios";
import { toast } from "react-toastify";
import {  Label,  Button } from "reactstrap";
// import detectEthereumProvider from "@metamask/detect-provider";
import loadinggif from "../../src/assets/images/loader.gif";
// var CircularJSON = require("circular-json");
import mini_abi from "./JSON/mini_abi.json"
const Step4 = (props) => {
  const [errormsg, setErrormsg] = useState(false);
  const [sccucesmsg, setSccucesmsg] = useState(false);
  const [depositerrormsg, setDepositerrorsmsg] = useState(false);
  const [Depositsccucesmsg, setDepositSccucesmsg] = useState(false);
  const [ScheduleErrormsg, setScheduleErrormsg] = useState(false);
  const [Schedulesccucesmsg, setScheduleSccucesmsg] = useState(false);
  const [Mintsccucesmsg, setMintSccucesmsg] = useState(false);
  const [MintErrormsg, setMintErrormsg] = useState(false);
  const [amountmsg, setAmountmsg] = useState(false);
  const [IsApproveloader, SetApproveLoader] = useState(false);
  const [IsMintloader, SetMintLoader] = useState(false);
  const [IsDepositloader, SetDepositLoader] = useState(false);
  const [IsScheduleloader, SetScheduleLoader] = useState(false);
  // const [IsLoader, setIsLoader] = useState(false);
  const [IsLoader, setIsLoader] = useState(true); // main
  const [IsApproveBtn, setIsApproveBtn] = useState(true); // for main
  // const [IsApproveBtn, setIsApproveBtn] = useState(false); // for testing purpose
  const [IsMintBtn, SetMintBtn] = useState(false); // for main
  // const [IsMintBtn, SetMintBtn] = useState(true); // you just need to uncomment this for testing purpose and comment false mintbutton
  const [IsDepositBtn, SetDepositBtn] = useState(false);
  const [IsScheduleBtn, SetScheduleBtn] = useState(false);
  //   const [infoAbi, SetinfoAbi] = useState({ fgh: "fgh" });
  //   const [infoContract, SetinfoContract] = useState("78954ertyujv74");
  const [infoAbi, SetinfoAbi] = useState([]);
  const [infoContract, SetinfoContract] = useState([]);
  const [byteCode, SetbyteCode] = useState("");
  // const [Totalsupplies, setTotalsupplies] = useState("");

  let bytecode;

  //====================== Deploy Function =================
  const DeployCall = async () => {
    let web3;
    setErrormsg(false);
    setSccucesmsg(false);
    SetApproveLoader(true);
    if (window.ethereum && window.ethereum.isMetaMask) {
      // await window.web3.currentProvider.enable();
      web3 = new Web3(window.ethereum && window.web3.currentProvider);

      const name = "b" + props.values.tokenname;
      const symbol = "b" + props.values.tokensymbol;
      // let arg_data = [name, symbol];

      // try {
      const accounts = await web3.eth.getAccounts();

      // SetMintBtn(true);
      // setSccucesmsg(true);
      // setIsApproveBtn(false);
      await new web3.eth.Contract(infoAbi)
        .deploy({
          data: byteCode,
          // data: '0x' + bytecode,
          arguments: [name, symbol],
          // arguments:["b"+"Debttokenname","b"+"DebttokenSymbol"]
        })
        .send({ from: accounts[0], gas: "3000000" })
        .on("transactionHash", function (hash) {
          console.log(hash, "hashtransaction here ");

          // SetMintBtn(true);
          // SetDepositBtn(false);
        })
        .then(function (newContract) {
          const contractDeployedAddress = newContract.options.address;
          setSccucesmsg(true);
          SetApproveLoader(false);
          SetDepositBtn(false);
          SetinfoContract(contractDeployedAddress);
          SetMintBtn(true);
          setIsApproveBtn(false);

          props.parentCallback("contractAddress", contractDeployedAddress);
        })
        .catch((e) => {
          SetApproveLoader(false);
          setErrormsg(true);
          SetMintBtn(false);
          setIsApproveBtn(true);
        });

      // let contractDeployedAddress = result.options.address;
    } else {
      console.log("Please install Metamask");
      setErrormsg(false);
      setIsApproveBtn(true);
    }
  };

  // const get_token_ids = async (symbol,names) => {

  // }
  //======================deploye script======================
  const apiCall = async () => {
    // let web3;
    // if (window.ethereum && window.ethereum.isMetaMask) {
    //   await window.web3.currentProvider.enable();
    //   web3 = new Web3(window.ethereum && window.web3.currentProvider);
    // } else {
    // }

    // window.ethereum.request({ method: 'eth_requestAccounts' })

    props.parentCallback("contractAbi", "b");
    props.parentCallback("contractAddress", "a");
    const backendAPIURL = process.env.REACT_APP_BE_API_URL;

    let url = `${backendAPIURL}api/bond/deploy_token`;
    // let url = "http://localhost:3002/api/bond/deploy_token";
    let headers = { "Content-Type": "application/json" };
    let res = await axios.post(url, { headers });
    // .then((res) =>{

    bytecode = res.data[0];
    let abi = res.data[1];
    // setinfocontract(a)
    // setinfoabi(b)

    SetbyteCode(bytecode);
    SetinfoAbi(abi);
    setIsLoader(false);
    props.parentCallback("contractAbi", abi);
  };

  useEffect(() => {
    if (props.currentStep !== 4) {
      return null;
    } else {
      apiCall();
    }

    // depositCollateral();
    // schedulesale();
    // transact();
    // minttoken();
    //   }, []);
  }, [props.currentStep]);

  // let colletral_contractaddress = "0x47F29bBAA96E0f153119a728d9eD4F33d02416d7";
  // let colletral_contractaddress = "0xc4e6178d25eEF7511A84FC924E918c40328B264D";
  // let rops_colletral_contractaddress = "0x104305425eB979F3fBB0327C524eae33DE54b031";

  // let colletral_contractaddress = "0x67f8dDC8fD5e4e901b1DC5e0b33f22B0f2b3673C";goeril
  // let colletral_contractaddress="0x7F851cDc52A81582b43e7e5f7B5875D8e9CF057B";polygon mumbai
  let colletral_contractaddress="0xE880FA3B5C02F2c758a24529a11360fE82FaeE5F";
  //====================bond contract - ABI and Contract Address End ====


  let contractAddress = props.values.collateralAddress;
  let collateralCurrency = props.values.collateralCurrency;
  let collateralName = props.values.collateralName;

  // for deposite colletral
  const depositCollateral = async () => {
    // let colletralprice;
    // try {

    setAmountmsg(false);
    SetDepositLoader(true);
    setDepositSccucesmsg(false);
    setDepositerrorsmsg(false);
    let web3;

    if (window.ethereum && window.ethereum.isMetaMask) {
      web3 = new Web3(window.ethereum && window.web3.currentProvider);
      window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      let dacc = accounts[0];

      //===============================
      // get balance of user

      const tokenInst = new web3.eth.Contract(mini_abi, contractAddress);
      var balance = await tokenInst.methods.balanceOf(dacc).call();
      // var balance = await web3.eth.getBalance(dacc);
      // var wallet = web3.eth.toWei(balance, 'ether');
      //===============================

      let exchangeRate;
      // let value = await get_token_ids(collateralCurrency,collateralName)

      // get_token_ids(collateralCurrency,collateralName).then((value) => {
      // debugger
      const coinAPI = process.env.REACT_APP_COIN_API_URL;

      await axios.get(`${coinAPI}coins/list`).then((res) => {
        const token_list = res.data;
        let fin_item;
        console.log(coinAPI,"console",token_list,"console1",collateralCurrency)
        console.log(coinAPI,"console",token_list,"console1",collateralName)
        token_list.map((item) => {
          if (
            item.symbol === collateralCurrency.toLowerCase() &&
            item.name.toUpperCase() === collateralName.toUpperCase()
          ) {
            // debugger
            fin_item = item;
            console.log("console-1",token_list,"console1",item)
          }
          
          return ""
        });
        if (fin_item === undefined) {
          toast.error("collateral Currency is Invalid");
        }

        // });

        if (fin_item) {
          let ids_ = fin_item.id;

          const coinAPI = process.env.REACT_APP_COIN_API_URL;
          let headers = { "Content-Type": "application/json","Access-Control-Allow-Origin": "*"};
          axios
            .get(`${coinAPI}simple/price?ids=${ids_}&vs_currencies=usd`,headers)
            .then((res) => {
              let colletralprice = parseInt(
                (props.values.amountToRaise * props.values.collateral) / 100
              );

              exchangeRate = res.data[ids_]["usd"];

              colletralprice = parseInt(colletralprice) * exchangeRate;

              const decimal_val = Math.pow(
                10,
                Number(props.values.collateralDecimal)
              );
              colletralprice = Number(colletralprice * decimal_val);
              console.log("balance --",balance)
              console.log("colletralprice --",colletralprice)

              if (Number(balance) > Number(colletralprice)) {
                let contract = new web3.eth.Contract(mini_abi, contractAddress);

                contract.methods
                  .transfer(colletral_contractaddress, String(colletralprice))
                  .send({ from: dacc, gas: "1420000", gasPrice: "17780000000" })
                  .on("transactionHash", function (hash) {
                    console.log(hash, "hashtransaction here ");
                  })
                  .then(function () {
                    setDepositSccucesmsg(true);
                    SetDepositBtn(false);
                    SetScheduleBtn(true);
                    SetDepositLoader(false);
                  })
                  .catch((e) => {
                    setDepositerrorsmsg(true);
                    SetDepositLoader(false);
                    SetDepositBtn(true);

                  });
              } else {
                console.log("Insufficient amount");
                SetDepositLoader(false);
                setAmountmsg(true);
              }
            })
            .catch((error) => {
              console.log("Error: Non Active Token, ", error);
            });
        }
      });
    } else {
      setDepositerrorsmsg(true);
      console.log("Need to install MetaMask");
      SetDepositLoader(false);
    }
  };

  // for shedule sale using contract
  const schedulesale = async () => {
    // try {
    SetScheduleLoader(true);
    setScheduleSccucesmsg(false);
    setScheduleErrormsg(false);

    if ((props.values.durationOfSaleStart, props.values.durationOfSaleEnd)) {
      setScheduleSccucesmsg(true);
      SetScheduleBtn(false);
      props.handleChange();
      SetScheduleLoader(false);
    } else {
      setScheduleErrormsg(true);
      SetScheduleLoader(false);

      console.log("Need to install MetaMask");
    }
    // }
    // catch {
    // 	setScheduleErrormsg(true);
    // }
  };

  //================================================here is debt code ========================

  let debt_abi = infoAbi;
  let debt_contractaddress = infoContract;

  const minttoken = async () => {
    SetMintLoader(true);
    setMintSccucesmsg(false);
    setMintErrormsg(false);
    let web3;

    if (window.ethereum && window.ethereum.isMetaMask) {
      web3 = new Web3(window.ethereum && window.web3.currentProvider);
      window.ethereum.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      let macc = accounts[0];

      let contract = new web3.eth.Contract(debt_abi, debt_contractaddress);
      
      await contract.methods
        .mint(
          colletral_contractaddress,
          // props.values.amountToRaise
          props.values.totalsuppliesss
        )
        .send({ from: macc, gas: "310000", gasPrice: "24500000000" })
      
        .then(function () {
          // const contractDeployedAddress = newContract.options.address;
          console.log("Mint Proccess");
          setMintSccucesmsg(true);
          SetMintBtn(false);
          SetDepositBtn(true);
          SetMintLoader(false);
          setIsApproveBtn(false);

        })
        .catch((e) => {
          if (e.code === 4001) {
            //user rejected the transaction
            setMintErrormsg(true);
            SetMintLoader(false);
            SetMintBtn(true);

          }
        });
      // setMintSccucesmsg(true);
    } else {
      setMintErrormsg(true);
      console.log("Need to install MetaMask");
    }
    // }
    // catch {
    // 	setDepositerrorsmsg(true);
    // }
  };

  return (
    <>
      {props.currentStep === 4 &&
        (!IsLoader ? (
          // { info > 0 && (
          <div className="my-card my-card2">
            <p>
              <h4 className="text-start pl-1">Sign Transactions</h4>
              <br />
              Now that your bond configuration is ready to go, you need to
              deploy it to the network by signing the following transactions
            </p>
            {errormsg ? (
              <p style={{ color: "red" }}>
                User Denied Request or Need to install MetaMask
              </p>
            ) : (
              <></>
            )}
            {sccucesmsg ? (
              <p style={{ color: "green" }}>Approved Successfully</p>
            ) : (
              <></>
            )}
            <Label for="approve_interatcions">Approve interatcions</Label>
            {IsApproveloader ? (
              <div className="img-btn">
                <img
                  src={loadinggif}
                  alt="Happy Emoticons"
                  className="demoimg"
                />
              </div>
            ) : (
              <>
                <Button
                  variant="primary"
                  onClick={DeployCall}
                  className="continue-btn"
                  disabled={!IsApproveBtn}
                >
                  Approve
                </Button>{" "}
              </>
            )}
            <Label for="tokens">
              Mint {props.values.totalsuppliesss} of b{props.values.tokenname}
            </Label>
            {/* {sccucesmsg ?<> */}
            {MintErrormsg ? (
              <p style={{ color: "red" }}>Not Minted or User Denied Request </p>
            ) : (
              <></>
            )}
            {Mintsccucesmsg ? (
              <p style={{ color: "green" }}>Minted Successfully</p>
            ) : (
              <></>
            )}
            {IsMintloader ? (
              <div className="img-btn">
                <img
                  src={loadinggif}
                  alt="Happy Emoticons"
                  className="demoimg"
                />
              </div>
            ) : (
              <>
                <Button
                  variant="primary"
                  onClick={minttoken}
                  className="continue-btn"
                  disabled={!IsMintBtn}
                >
                  Mint
                </Button>{" "}
              </>
            )}
            <Label for="deposit_collateral">Deposited Collateral</Label>
            {amountmsg ? (
              <p style={{ color: "red" }}>Insufficient Amount </p>
            ) : (
              <></>
            )}
            {depositerrormsg ? (
              <p style={{ color: "red" }}>User Denied Request </p>
            ) : (
              <></>
            )}
            {Depositsccucesmsg ? (
              <p style={{ color: "green" }}>Successfully Deposited</p>
            ) : (
              <></>
            )}
            {IsDepositloader ? (
              <div className="img-btn">
                <img
                  src={loadinggif}
                  alt="Happy Emoticons"
                  className="demoimg"
                />
              </div>
            ) : (
              <>
                <Button
                  variant="primary"
                  onClick={depositCollateral}
                  className="continue-btn"
                  disabled={!IsDepositBtn}
                >
                  Deposit
                </Button>{" "}
              </>
            )}
            <Label for="schedule_sale">Schedule sale</Label>

            {ScheduleErrormsg ? (
              <p style={{ color: "red" }}>Not Scheduled</p>
            ) : (
              <></>
            )}
            {Schedulesccucesmsg ? (
              <p style={{ color: "green" }}>
                Date Scheduled from{" "}
                {new Date(props.values.durationOfSaleStart).toString()} to{" "}
                {new Date(props.values.durationOfSaleEnd).toString()}{" "}
              </p>
            ) : (
              <></>
            )}
            {IsScheduleloader ? (
              <div className="img-btn">
                <img
                  src={loadinggif}
                  alt="Happy Emoticons"
                  className="demoimg"
                />
              </div>
            ) : (
              <>
                <Button
                  variant="primary"
                  onClick={schedulesale}
                  className="continue-btn"
                  disabled={!IsScheduleBtn}
                >
                  Schedule
                </Button>{" "}
              </>
            )}
          </div>
        ) : (
          <div className="text-center">
            <img src={loadinggif} alt="Happy Emoticons" className="demoimg" />
            <p style={{ textAlign: "center" }}>Creating Debt Token...</p>
          </div>
        ))}
    </>
  );
};

export default Step4;
